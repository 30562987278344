import React, { useState } from 'react';

import type { SideNavState } from './types';
import {
	SetSideNavDesktopVisibility,
	SetSideNavMobileVisibility,
	SetSideNavVisibilityState,
	SideNavDesktopVisibility,
	SideNavMobileVisibility,
	SideNavVisibilityState,
} from './visibility-context';

/**
 * Manages the side nav visibility state and provides the context.
 */
export const SideNavVisibilityProvider = ({ children }: { children: React.ReactNode }) => {
	// Defaults to null so we can determine if the value has been set yet (for SSR)
	const [sideNavState, setSideNavState] = useState<SideNavState | null>(null);

	// NOTE: This is outdated with the refactors in fg('platform_nav4_side_nav_flyout_animation') and will be cleaned up.
	const [visibleOnDesktop, setVisibleOnDesktop] = useState<boolean | null>(null);
	// NOTE: This is outdated with the refactors in fg('platform_nav4_side_nav_flyout_animation') and will be cleaned up.
	const [visibleOnMobile, setVisibleOnMobile] = useState(false);

	return (
		<SideNavVisibilityState.Provider value={sideNavState}>
			<SetSideNavVisibilityState.Provider value={setSideNavState}>
				{/* The below providers are outdated with the refactors in fg('platform_nav4_side_nav_flyout_animation') and will be cleaned up. */}
				<SideNavDesktopVisibility.Provider value={visibleOnDesktop}>
					<SetSideNavDesktopVisibility.Provider value={setVisibleOnDesktop}>
						<SideNavMobileVisibility.Provider value={visibleOnMobile}>
							<SetSideNavMobileVisibility.Provider value={setVisibleOnMobile}>
								{children}
							</SetSideNavMobileVisibility.Provider>
						</SideNavMobileVisibility.Provider>
					</SetSideNavDesktopVisibility.Provider>
				</SideNavDesktopVisibility.Provider>
			</SetSideNavVisibilityState.Provider>
		</SideNavVisibilityState.Provider>
	);
};
