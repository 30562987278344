/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import React from 'react';

import { jsx } from '@compiled/react';

import { cssMap } from '@atlaskit/css';
import { fg } from '@atlaskit/platform-feature-flags';
import { token } from '@atlaskit/tokens';

const styles = cssMap({
	root: {
		gap: token('space.100'),
		width: '100%',
		gridColumn: 2,
	},
	oldLayoutStyles: {
		display: 'flex',
		justifyContent: 'end',
		'@media (min-width: 30rem)': {
			justifyContent: 'center',
		},
	},
	newLayoutStyles: {
		/**
		 * On small viewports the search is an icon button.
		 *
		 * We want the icon button (and Create button) to hug the right / end.
		 */
		display: 'flex',
		justifyContent: 'end',
		/**
		 * On large viewports the search is a text field.
		 *
		 * We want our search bar to adapt to available space (up to a maximum of `780px`)
		 * but we need to set that constraint in the grid.
		 *
		 * The parent grid on TopBar gives `CommonActions` an `auto` width,
		 * so `CommonActions` must set up further layout constraints.
		 *
		 * Setting it just on the search directly does not seem possible,
		 * because without the column `minmax` the search collapses to its `min-content` width.
		 *
		 * Because we need to set it on the column, we need to make an assumption / requirement
		 * that the search is always the first item.
		 */
		'@media (min-width: 48rem)': {
			display: 'grid',
			// This column constraint is only relevant for the search bar
			// It only applies to the first column
			gridTemplateColumns: 'minmax(min-content, 780px)',
			// Additional items will be inserted into implicitly-created columns
			gridAutoFlow: 'column',
			// Determines the width of any implicitly-created columns
			gridAutoColumns: 'minmax(min-content, max-content)',
			/**
			 * The Search Platform component uses a `780px` breakpoint which is not aligned
			 * with our `48rem` / `768px` breakpoint.
			 *
			 * This means there is a small range where the search is an icon button and we
			 * are still on the grid layout. So there is a `32px` wide icon button inside
			 * of a potentially `780px` column.
			 *
			 * We need to apply `justifyItems: 'end'` to ensure that icon button hugs the end of its column.
			 *
			 * We can remove this once the Search Platform component is aligned with our breakpoints.
			 */
			justifyItems: 'end',
		},
	},
});

/**
 * __Common actions__
 *
 * Wrapper for the actions in the middle of the top navigation.
 */
export const CommonActions = ({ children }: { children: React.ReactNode }) => (
	<div
		css={[
			styles.root,
			fg('platform_design_system_nav4_top_nav_columns')
				? styles.newLayoutStyles
				: styles.oldLayoutStyles,
		]}
	>
		{children}
	</div>
);
