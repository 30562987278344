import { createContext, useContext, useEffect } from 'react';

import noop from '@atlaskit/ds-lib/noop';

import { type SkipLinkData } from './types';

export type SkipLinksContextData = {
	registerSkipLink: (skipLinkData: SkipLinkData) => void;
	unregisterSkipLink: (id: string | undefined) => void;
};

/**
 * Provides a way to register and unregister skip links
 */
export const SkipLinksContext = createContext<SkipLinksContextData>({
	registerSkipLink: noop,
	unregisterSkipLink: noop,
});

const useSkipLinks = () => useContext(SkipLinksContext);

export const useSkipLink = (id: string, label: string, listIndex?: number) => {
	const { registerSkipLink, unregisterSkipLink } = useSkipLinks();
	useEffect(() => {
		registerSkipLink({ id, label, listIndex });
		return () => {
			unregisterSkipLink(id);
		};
	}, [id, label, listIndex, registerSkipLink, unregisterSkipLink]);
};
